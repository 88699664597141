import Header from 'components/typography/Header'
import { useDispatch } from 'react-redux'
import P from 'components/typography/Paragraph'
import SocialIcon from 'components/misc/SocialIcon'
import Link from 'components/misc/Link'
import { toggleModal } from 'store/ui/actions'
import styles from './styles/Footer.css'

const VISUALIZATIONS_EXAMPLE = '/visualizations/global-analysis-of-genome-transcriptome-9L4dJr?dois[0]=10.1038%2Fmsb.2012.40&dois[1]=10.7554%2Felife.05068&focusedElement=10.7554%2Felife.05068'

const About = () => (
  <div className={styles.aboutContainer}>
    <img
      width='128px'
      height='41px'
      alt='scite logo' className={styles.logo} src={`${CONFIG.assetsBaseURL}/images/logo.svg`}
    />
    <P className={styles.aboutText}>
      scite is a Brooklyn-based organization that helps researchers better discover and understand research articles through Smart Citations–citations that display the context of the citation and describe whether the article provides supporting or contrasting evidence. scite is used by students and researchers from around the world and is funded in part by the National Science Foundation and the National Institute on Drug Abuse of the National Institutes of Health.
    </P>
    <Contact />
  </div>
)

const Contact = () => (
  <div className={styles.contact}>
    <Header level={6} className={styles.header}>Contact Info</Header>
    <div>
      <P className={styles.address}>hi@scite.ai</P>
      <P className={styles.address}>10624 S. Eastern Ave., Ste. A-614</P>
      <P className={styles.address}>Henderson, NV 89052, USA</P>
    </div>
  </div>
)

const SocialIcons = () => (
  <div className={styles.iconsLayout}>
    <SocialIcon className={styles.icon} type='twitter' />
    <SocialIcon className={styles.icon} type='facebook' />
    <SocialIcon className={styles.icon} type='instagram' />
    <SocialIcon className={styles.icon} type='tiktok' />
    <SocialIcon className={styles.icon} type='linkedin' />
    <SocialIcon className={styles.icon} type='youtube' />
  </div>
)

const StaticLinks = () => {
  const dispatch = useDispatch()

  return (
    <div className={styles.staticContainer}>
      <div className={styles.staticLayout}>
        <P type='small' className={styles.staticLinks}>
          <Link className={styles.link} href='https://scite.ai/blog' target='_blank' rel='noopener noreferrer'>Blog</Link>
          <Link className={styles.link} to='/terms'>Terms and Conditions</Link>
          <Link className={styles.link} to='/apiterms'>API Terms</Link>
          <Link className={styles.link} to='/policy'>Privacy Policy</Link>
          <Link className={styles.link} href='mailto:hi@scite.ai'>Contact</Link>
          <Link className={styles.link} onClick={() => dispatch(toggleModal('cookie', true, 'generic'))}>Cookie Preferences</Link>
          <Link className={styles.link} onClick={() => dispatch(toggleModal('cookie', true, 'do_not_sell'))}>Do Not Sell or Share My Personal Information</Link>
        </P>
        <SocialIcons />
      </div>
    </div>
  )
}

const ProductLinks = () => (
  <div className={styles.productContainer}>
    <div>
      <Header level={6}>Product</Header>
      <div className={styles.products}>
        <Link className={styles.link} to='/extension-install'>Browser Extension</Link>
        <Link className={styles.link} to='/assistant'>Assistant by scite</Link>
        <Link className={styles.link} to='/search/citations'>Citation Statement Search</Link>
        <Link className={styles.link} to='/?referenceCheck=true'>Reference Check</Link>
        <Link className={styles.link} to={VISUALIZATIONS_EXAMPLE}>Visualizations</Link>
        <Link className={styles.link} to='/create-dashboard'>Dashboards</Link>
        <Link className={styles.link} to='/journals'>Explore Journals</Link>
        <Link className={styles.link} to='/affiliations'>Explore Organizations</Link>
        <Link className={styles.link} to='/institutions'>Explore Funders</Link>
        <Link className={styles.link} to='/badge'>Embedding Badge</Link>
        <Link className={styles.link} to='/embed-citation-search'>Embedding Citation Search</Link>
        <Link className={styles.link} to='/pricing'>Pricing</Link>
      </div>
    </div>
    <div>
      <Header level={6}>Resources</Header>
      <div className={styles.resources}>
        <Link className={styles.link} href='https://scite.ai/blog' target='_blank' rel='noopener noreferrer'>Blog</Link>
        <Link className={styles.link} href='https://help.scite.ai' target='_blank' rel='noopener noreferrer'>Help & FAQ</Link>
        <Link className={styles.link} to='/accessibility-statement'>Accessibility Statement</Link>
        <Link className={styles.link} to='/apiterms'>API Terms</Link>
        <Link className={styles.link} to='/partners/academic-institutions'>For Universities & Governments</Link>
        <Link className={styles.link} to='/partners/researchers'>For Researchers</Link>
        <Link className={styles.link} to='/partners/publishers'>For Publishers</Link>
        <Link className={styles.link} to='/partners/pharmaceuticals-and-biotech'>For Corporate, Pharma & Enterprise</Link>
        <Link className={styles.link} to='/author-marketing'>Author Marketing</Link>
        <Link className={styles.link} to='/affiliate' target='_blank' rel='noopener noreferrer'>Become an Affiliate</Link>
        <Link className={styles.link} to='/pricing?tab=organizations'>Get an organization trial or quote</Link>
        <Link className={styles.link} to='/data-and-services'>scite Data & Services</Link>
      </div>
    </div>
    <div>
      <Header level={6}>About</Header>
      <div className={styles.about}>
        <Link className={styles.link} to='/news-and-press' target='_blank' rel='noopener noreferrer'>News & Press</Link>
        <Link className={styles.link} to='/jobs'>Careers</Link>
        <Link className={styles.link} href='https://direct.mit.edu/qss/article/2/3/882/102990/scite-A-smart-citation-index-that-displays-the'>Read our Paper</Link>
        <Link className={styles.link} to='/data-and-services'>Coverage</Link>
      </div>
    </div>
  </div>
)

const Copyright = () => (
  <div className={styles.copyrightContainer}>
    <div className={styles.copyrightLayout}>
      <P type='small' className={styles.copyright}>Copyright © {new Date().getFullYear()} scite LLC. All rights reserved.</P>
      <P type='small' className={styles.madeWithLove}>Made with 💙 for researchers</P>

      <div className={styles.rsssFamily}>
        <P type='small' className={styles.rsssBlurb}>Part of the Research Solutions Family.</P>
        <img src={`${CONFIG.assetsBaseURL}/images/logo-rs-black-new.png`} className={styles.rsssLogo} />
      </div>
    </div>
  </div>
)

export const Footer = () => (
  <div className={styles.layout}>
    <div className={styles.mainLayout}>
      <About />
      <ProductLinks />
    </div>
    <StaticLinks />
    <Copyright />
  </div>
)

export default (Footer)
