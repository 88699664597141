import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Link from '../misc/Link'
import Header from '../typography/Header'
import Paragraph from '../typography/Paragraph'
import { toggleModal, setBannerClosed } from 'store/ui/actions'
import styles from './styles/Banner.css'
import classNames from 'classnames'

import qs from 'query-string'
import dateString from 'lib/date-string'
import { setMode } from '../../store/login-modal/actions'
import { normalizeLicenseName } from '../../lib/organization-license-name'
import { getLocalStorage } from '../../lib/get-local-storage'
import { createTrialLink } from '../../lib/create-trial-link'
import { getSessionStorage } from '../../lib/get-session-storage'

const VISUALIZATIONS_EXAMPLE = '/visualizations/global-analysis-of-genome-transcriptome-9L4dJr?dois[0]=10.1038%2Fmsb.2012.40&dois[1]=10.7554%2Felife.05068&focusedElement=10.7554%2Felife.05068'
const DEFAULT_BANNER_CONTENT = null

const isOlderThanDays = ({ timestamp, numDays }) => {
  if (!timestamp) {
    return false
  }
  const timestampDate = new Date(timestamp)
  const now = new Date()

  // Calculate the date and time for 2 days ago
  const numDaysAgo = new Date(now.getTime() - numDays * 24 * 60 * 60 * 1000)

  // Compare the parsed date with the calculated date
  return timestampDate < numDaysAgo
}

const licenseMessage = (license) => {
  const institutionName = normalizeLicenseName(license?.name)
  if (license?.status === 'TRIALING' || license?.status === 'TRIAL_READY') {
    if (license?.trialEndDate) {
      return `${institutionName} is trialing scite until ${dateString(license?.trialEndDate)}.`
    } else {
      return `${institutionName} is trialing scite.`
    }
  }
  return `${institutionName} subscribes to an organization-wide license of scite.`
}

const getContent = ({ dispatch, bannerContent, subscription, email, createdAt, license, referralPartner, activatedTrial, pathname }) => {
  const banners = {
    visualizations: {
      to: VISUALIZATIONS_EXAMPLE,
      content: (
        <>
          <img alt='tag' className={styles.tagImg} src={`${CONFIG.assetsBaseURL}/images/icons/tag.svg`} />
          Announcing Visualizations: see scite Smart Citations in context. <span className={styles.bannerLink}>Try it now.</span>
        </>
      )
    },
    zotero: {
      href: 'https://github.com/scitedotai/scite-zotero-plugin',
      content: (
        <>
          <img alt='tag' className={styles.tagImg} src={`${CONFIG.assetsBaseURL}/images/icons/tag.svg`} />
          Announcing the scite Zotero Plugin: See how articles in your library have been supported, contrasted, or mentioned by other articles. <span className={styles.bannerLink}>Try Now.</span>
        </>
      )
    },
    wiley: {
      handleClick: () => dispatch(setMode('register')) && dispatch(toggleModal('login')),
      content: (
        <>
          Wiley provides 1 free scite report. Register now and access all scite features. <span className={styles.bannerLink}> Start your trial now.</span>
        </>
      )
    },
    reprintsReports: {
      handleClick: () => dispatch(setMode('register')) && dispatch(toggleModal('login')),
      content: (
        <>
          Enjoy your Article Galaxy access to this full scite report page. If you'd like to explore more beyond this report, <span className={styles.bannerLink}> register for a {CONFIG.trialLength}-day trial.</span>
        </>
      )
    },
    reprintsSearch: {
      content: (
        <div className={styles.textLeft}>
          <Header level={4}>
            Welcome from Article Galaxy!
          </Header>

          <Paragraph className={styles.bannerSearchAG}>
            scite recently joined the Research Solutions family. We already created an account for you here. Searching for publications with sorts and filters is free,
            but advanced features like reading Citation Statements, setting alerts, or using Assistant
            {' '}<Link to='/pricing' underline>require a paid plan</Link> or an
            {' '}<Link to='/request-a-demo' underline>organizational license</Link>. Try it out, or
            {' '}<Link to='/request-a-demo' underline>get in touch if you&#8217;re interested in a demo</Link>.
          </Paragraph>
        </div>
      )
    },
    citationStatementSearch: {
      href: 'https://scite.ai/search/citations',
      content: (
        <>
          <img alt='tag' className={styles.tagImg} src={`${CONFIG.assetsBaseURL}/images/icons/tag.svg`} />
          Find evidence, analyses, and expert opinions on any subject. <span className={styles.bannerLink}>Search over {CONFIG.defaultCitationsTotalText} Citation Statements.</span>
        </>
      )
    },
    upgrade: {
      to: '/pricing',
      content: (
        <div>
          <span className={styles.bannerLink}>Upgrade now</span> to continue using scite for as low as $16.00/month.
        </div>
      )
    },
    startTrial: {
      href: createTrialLink(),
      content: (
        <div>
          <span className={styles.bannerLink}>Activate {CONFIG.trialLength} day trial</span> to get an unlimited 7 day preview of scite features. Use ACTIVATEBANNER20 to get 20% off after your trial.
        </div>
      )
    },
    temporaryOutage: {
      content: (
        <>
          We are currently experiencing intermittent technical issues and are actively working to resolve them, sorry for any inconvenience this may cause!
        </>
      )
    },
    announceRSSS: {
      content: (
        <div>
          🚀 We are thrilled to announce that scite has been acquired by Research Solutions! <a className={styles.bannerLink} target='_blank' href='https://www.researchsolutions.com/resources/press-releases/research-solutions-announces-acquisition-of-scite' rel='noreferrer'>Full Press Release</a>
        </div>
      )
    },
    assistantOutage: {
      content: (
        <>
          We are currently experiencing intermittent degraded performance with Assistant and are actively working to resolve it, sorry for any inconvenience this may cause!
        </>
      )
    },
    organizationLicense: {
      content: (
        <>
          Welcome! {licenseMessage(license)} {
            email
              ? (
                <>
                  Check out our <Link href='https://help.scite.ai/' target='_blank' rel='noopener noreferrer' className={styles.bannerLink}>FAQ / Help</Link> or see what we offer <Link to='/partners/researchers' className={styles.bannerLink}>for researchers</Link>.
                </>
                )
              : (
                <>
                  You are not required to create an account or log in to search scite. However, <Link onClick={() => dispatch(toggleModal('login'))} className={styles.bannerLink}>an account is needed</Link> to access many scite features (e.g. notifications, Assistant history, and dashboards).
                </>
                )
          }
        </>
      )
    }
  }

  if (CONFIG.temporaryOutage) {
    return banners.temporaryOutage
  }

  if (CONFIG.assistantOutage) {
    return banners.assistantOutage
  }

  if (bannerContent === 'organizationLicense') {
    return banners.organizationLicense
  }

  if (CONFIG.announceRSSS) {
    return banners.announceRSSS
  }

  if (referralPartner === 'wiley' && !subscription) {
    return banners.wiley
  }

  if (referralPartner === 'reprints' && !subscription && pathname.includes('/reports')) {
    return banners.reprintsReports
  }

  if (referralPartner === 'reprints' && !subscription && pathname.includes('/search')) {
    return banners.reprintsSearch
  }

  // post-trialing
  if (subscription?.plan === 'free' && activatedTrial) {
    return banners.upgrade
  }

  if (subscription?.plan === 'free' && !activatedTrial && isOlderThanDays({ timestamp: createdAt, numDays: 2 })) {
    return banners.startTrial
  }

  if (!bannerContent) {
    return banners[DEFAULT_BANNER_CONTENT]
  }

  return banners[bannerContent]
}

const Banner = () => {
  const dispatch = useDispatch()
  const [checkedStorage, setCheckedStorage] = useState(false)
  const bannerContent = useSelector(({ ui }) => ui.bannerContent)
  const bannerClosed = useSelector(({ ui }) => ui.bannerClosed)
  const referralPartner = useSelector(({ ui }) => ui.referralPartner)
  const license = useSelector(({ ui }) => ui.organizationLicense)
  const user = useSelector(({ ui }) => ui.user)
  const { search, pathname } = useLocation()
  const content = getContent({
    dispatch,
    bannerContent,
    subscription: user?.subscription,
    email: user?.email,
    createdAt: user?.createdAt,
    referralPartner,
    license,
    activatedTrial: user?.activatedTrial,
    pathname
  })

  const query = qs.parse(search)

  const handleClose = () => {
    const closeTemporarily = referralPartner === 'reprints' || bannerContent === 'organizationLicense'
    const localStorage = getLocalStorage()
    const sessionStorage = getSessionStorage()

    if (localStorage && !closeTemporarily) {
      localStorage.setItem('banner-generic-4', 'closed')
    }

    if (sessionStorage && closeTemporarily) {
      sessionStorage.setItem('banner-generic-4', 'closed')
    }

    dispatch(setBannerClosed(true))
  }

  useEffect(() => {
    if (CONFIG.temporaryOutage) {
      setCheckedStorage(true)
      dispatch(setBannerClosed(false))
      return
    }

    const localStorage = getLocalStorage()
    const sessionStorage = getSessionStorage()
    if (localStorage?.getItem('banner-generic-4') === 'closed' || sessionStorage?.getItem('banner-generic-4') === 'closed' || query.bannerClosed === 'true') {
      dispatch(setBannerClosed(true))
    }

    setCheckedStorage(true)

    if (pathname.includes('/search/citations') && (!bannerContent || bannerContent === 'citationStatementSearch')) {
      dispatch(setBannerClosed(true))
    }
  }, [])

  if (!content) {
    return null
  }

  if (!checkedStorage) {
    return null
  }

  if (pathname.includes('/assistant')) {
    return null
  }

  return (
    <div
      className={classNames(styles.navBanner, {
        [styles.bannerClosed]: bannerClosed
      })}
    >
      <div className={styles.closeBtn} onClick={() => handleClose()}>
        <i className='fa fa-lg fa-times' />
      </div>
      {(content.href || content.to || content?.handleClick)
        ? (
          <Link
            className={styles.link}
            href={content?.href}
            to={content?.to}
            onClick={content?.handleClick}
          >
            {content.content}
          </Link>
          )
        : (
          <div>{content.content}</div>
          )}
    </div>
  )
}

export default Banner
